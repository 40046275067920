<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                O subdistrito de Pedras é pertencente ao distrito de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_aguas_claras' }"
                  >Águas Claras</router-link
                >
                e está distante 41 km da sede do município de Mariana.
                Localiza-se em região onde o relevo é predominantemente
                montanhoso com a presença de numerosos afloramentos rochosos em
                seus campos. A vegetação local predominante é a mata atlântica
                em meio a extensões de pastagem, e o Rio Gualaxo do Norte
                permeia o seu território.
              </p>
              <p>
                Sua origem se deu, provavelmente, no século XVIII, a partir da
                ocupação da região por garimpeiros em busca do ouro abundante
                nos arredores e por produtores rurais dedicados ao fornecimento
                de produtos alimentícios para a população local. Os principais
                tipos de produção à época eram milho, feijão, arroz e cana de
                açúcar, além da criação de gado leiteiro.
              </p>
              <p>
                Um importante exemplar da arquitetura rural colonial da região,
                a Fazenda Gualaxo, remonta a esse período histórico e preserva
                suas características estilísticas como a edificação sede com
                gabarito de dois pavimentos, porão e alpendre com ermida (ou
                quarto para hospedagem), seguindo a configuração das casas
                bandeiristas. O sistema construtivo empregado na edificação é
                com estrutura de madeira e vedação em adobe, tendo passado por
                intervenções pontuais com técnicas contemporâneas.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_pedras_01.png"
          />
          <p
            style="font-size: 0.5em; text-align: center; width: 100%"
            class="mr-md-2"
          >
            Placa de sinalização do subdistrito de Pedras. Foto: Flora Passos,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_pedras_02.png"
          />
          <p
            style="font-size: 0.5em; text-align: center; width: 100%"
            class="mr-md-2"
          >
            Vista de uma área de pastagem e da criação de bovinos no subdistrito
            de Pedras. Foto: Flora Passos, 2019.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Sobre a atual ocupação de Pedras, pode-se destacar a grande
                presença de sítios e fazendas. Hoje em dia, as principais
                atividades econômicas desenvolvidas em Pedras são a produção de
                leite e a agricultura de subsistência. A centralidade urbana do
                subdistrito está localizada nos arredores da Capela de Santo
                Antônio, existindo ainda diversas casas situadas nos terrenos
                que margeiam a estrada que liga Paracatu de Baixo e Furquim. As
                principais áreas atingidas com o rompimento da barragem de
                Fundão foram as situadas em cotas mais baixas, às margens do Rio
                Gualaxo. A lama alcançou e destruiu diversas edificações parcial
                ou integralmente , além de extensões de pastagem e cultivo das
                propriedades ali existentes. Vale citar também a destruição da
                principal ponte de acesso ao povoamento, que acarretou em seu
                isolamento, trazendo diversas dificuldades para toda a população
                de Pedras, como a perda de acesso ao abastecimento de
                eletricidade, água e alimentos e a impossibilidade de escoamento
                das produções rurais.
              </p>
              <p>
                Apesar de sua vocação essencialmente rural, Pedras também se
                apresenta como ponto comercial importante para as localidades
                vizinhas, que mantêm relações econômicas constantes no
                subdistrito, a exemplo de Borba, onde não há estabelecimentos
                para o atendimento de seus moradores. O Bar da Dalva e o
                Restaurante da Creonice são os pontos comerciais mais procurados
                em Pedras, consistindo também em ponto de encontro e
                socialização dos moradores da região.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Outro local de reunião da comunidade de Pedras é a Capela de
                Santo Antônio, templo católico onde são realizadas as
                celebrações e festividades religiosas locais, que atraem fiéis
                de toda a região. Os principais festejos que lá ocorrem são: a
                festa do padroeiro de Pedras, Santo Antônio, no mês de junho; a
                festa do Menino Jesus, em setembro; a festa de Nossa Senhora
                Aparecida, em outubro; a festa de Nossa Senhora das Graças, em
                novembro; a Folia de Reis, em dezembro, que devido à sua grande
                importância, foi registrada como patrimônio cultural do estado
                de Minas Gerais; e a festa de São Sebastião, em janeiro. Além
                destas datas comemorativas especiais, as missas celebradas em
                Pedras eram mais do que atos litúrgicos, mas também
                oportunidades para que os moradores das comunidades vizinhas se
                encontrassem.
              </p>
              <p>
                Ainda sobre a socialização em Pedras, o campo de futebol que lá
                existia era um local considerado como importante ponto de
                convívio e diversão dos membros da comunidade. O time de futebol
                do subdistrito, o Reunidos Futebol Clube, costumava receber
                times da região, promovendo ainda a interação da comunidade com
                moradores de outras localidades. Após o rompimento da barragem
                de Fundão, o campo e seus vestiários foram soterrados, levando o
                time a treinar em um campo alugado, distante do povoado.
              </p>
              <p>
                Nos arredores do núcleo urbano de Pedras existem várias
                cachoeiras que eram frequentemente visitadas por pessoas de toda
                a região em busca de momentos de lazer em suas águas límpidas.
                Era costume que os visitantes se encontrassem, tomassem banho no
                rio, realizassem piqueniques às suas margens e acampassem por
                lá. Atualmente, as cachoeiras se encontram abandonadas, pois não
                há mais visitantes como antes. Mesmo nas que não foram
                alcançadas pela lama do rompimento da barragem, as pessoas têm
                receio de se banharem pela incerteza da presença de metais
                tóxicos. No ano de 2005, o Povoado de Pedras, abrangendo a
                Capela de Santo Antônio e a Fazenda do Gualaxo, foi incluído no
                Inventário de Proteção do Acervo Cultural – IPAC.
              </p>
              <p style="text-align: center">
                <strong
                  >Autora: Ludmilla Guimarães Novaes e Oliveira<sup
                    >1</sup
                  ></strong
                >
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-center align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_pedras_03.png"
          />
          <p
            style="font-size: 0.5em; text-align: center; width: 100%"
            class="mr-md-2"
          >
            Vista da fachada frontal e lateral direita da Capela de Santo
            Antônio em Pedras, em dia de celebração. Foto: Flora Passos, 2019.
          </p>
        </v-col>
      </v-row>
    </section>
    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela Universidade Federal de
              Ouro Preto. Assessora técnica na ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
